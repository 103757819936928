import React from "react";
import '../styles/footer.css';
import { MdLocationOn, MdEmail } from "react-icons/md";
import { BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { FaPhone } from "react-icons/fa"

const Footer = () => {
    return (
        <footer className="flex flex-col bg-black w-full">
            <div className="ContactsFooter flex h-56">
                <div className="SubSectionFooter w-1/3">
                    <h1>Locations</h1>
                    <nav className="ItemsFooter">
                        <ul>
                            <li>
                                <a href="https://goo.gl/maps/mL5m51exkJd7PJxY9"
                                    target="_blank" rel="noreferrer" className="flex justify-center text-lg text-white">
                                    <MdLocationOn className="IconF w-7 h-7 pr-2"/>
                                    2317 Davis Blvd, Naples, FL. 34104.
                                </a>  
                            </li>
                            <li>
                                <a href="https://goo.gl/maps/qsGCDuZD3YzaSXrD8"
                                    target="_blank" rel="noreferrer" className="flex justify-center text-lg text-white">
                                    <MdLocationOn className="IconF w-7 h-7 pr-2"/>
                                    1202 Ne Pine Island Road, Cape Coral, FL. 33909
                                </a>
                            </li>
                            <li>
                                <a href="https://goo.gl/maps/1euymko3QJrF8JZAA"
                                    target="_blank" rel="noreferrer" className="flex justify-center text-lg text-white">
                                    <MdLocationOn className="IconF w-7 h-7 pr-2"/>
                                    4095 Via Sienna Cir Sarasota, FL. 34243
                                </a> 
                            </li>
                        </ul>
                    </nav>   
                </div>
                <div className="Separator-bar"></div>
                <div className="SubSectionFooter w-1/3">
                    <h1 className="pb-7">Contact Us</h1>
                    <nav className="ItemsFooter">
                        <ul>
                            <li>
                            <a href="https://api.whatsapp.com/send?phone=+1239-785-5433&text=Hello, I need more information!"
                                target="_blank" rel="noreferrer" className="flex justify-center text-lg text-white">
                                <BsWhatsapp className="IconF w-7 h-7 pr-2"/>
                                239-785-5433 
                            </a> 
                            </li>
                            <li>
                                <a href="tel:239-288-8281"
                                    target="_blank" rel="noreferrer" className="flex justify-center text-lg text-white">
                                    <FaPhone className="IconF w-7 h-7 pr-2"/>
                                    239-288-8281
                                </a>
                            </li>
                            <li>
                                <a href="mailto:sales@aasdgaragedoors.com"
                                    target="_blank" rel="noreferrer" className="flex justify-center text-lg text-white">
                                    <MdEmail className="IconF w-7 h-7 pr-2"/>
                                    sales@aasdgaragedoors.com 
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="Separator-bar"></div>
                <div className="SubSectionFooter w-1/3">
                    <h1>Social Network</h1>
                    <nav className="ItemsFooter">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100086535823508&mibextid=9R9pXO"
                                    target="_blank" rel="noreferrer" className="flex justify-center text-lg text-white">
                                    <BsFacebook className="IconF w-7 h-7 pr-2"/>
                                    Sarasota Garage Door
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/aasamedaygaragedoorsarasota?igshid=OGQ5ZDc2ODk2ZA== "
                                    target="_blank" rel="noreferrer" className="flex justify-center text-lg text-white">
                                    <BsInstagram className="IconF w-7 h-7 pr-2"/>
                                    Sarasota Garage Door
                                </a>
                            </li>
                        </ul>
                    </nav>   
                </div>
            </div>
            
            <div className="ReservedRight w-full py-4 text-xs bg-black">
                <h6 className=" text-white">AA Same day Sarasota® 2023. All rights reserved Powered by  <a href="https://juliancarlosama.com" target="_blank" rel="noreferrer" >Julian Carlosama</a></h6>
            </div>
        </footer>
    );
}

export default Footer;
