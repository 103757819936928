import React from "react";
import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./components/pages/Home";
import Services from './components/pages/Services';
import ContactUs from './components/pages/ContactUs';

const App = () => {

    {/*const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<Root />} >
                <Route index element={<Home />} />
                <Route path="/services" element={<Services />} />
            </Route>

        )
        )*/}
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="services" element={<Services />}/>
                    <Route path='garageDoors' element={<Services />} />
                    <Route path='Gallery' element={<Services />} />
                    <Route path='AboutUs' element={<Services />} />
                    <Route path='/ContactUs' element={<ContactUs />} />
                </Routes>
            </div>
        </Router> 
    )
};





{/*const Root = () => {
    return (
        <>
            <div>
                <Link to='/'>Home</Link>
                <Link to='/services'> Services</Link>
            </div>
            <div>
                <Outlet />
            </div>
        </>
    )
}*/}


export default App;