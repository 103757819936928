import React from "react";
import BurgerMenu from "../burgerMenu";
import BannerImg from "../bannerImgSect";
import ImgBan from '../../assets/bannerImages/contactUs.jpeg';
import { BsWhatsapp } from "react-icons/bs";
import Footer from "../footer";

const ContactUs = () => {
    return (
        <section>
            <BurgerMenu />
            <BannerImg Img={ImgBan} Title={'Contact Us'} />
            <div>
                <div>
                    <h1>EMERGENCY 24 HOUR SERVICE</h1>
                    <div className="flex flex-col items-center w-2/4">
                        <a href="https://api.whatsapp.com/send?phone=+1239-785-5433&text=Hello, I need more information!"
                            target="_blank" rel="noreferrer" className="flex justify-center text-lg">
                                <BsWhatsapp className="w-7 h-7 pr-2"/>
                                239-785-5433 
                        </a>
                        
                    </div>
                    
                </div>
                
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3542.7758172229646!2d-82.50371250043632!3d27.38271591497237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c33f1a0c7cd365%3A0x4e1733a585c31cb3!2s4095%20Via%20Sienna%20Cir%2C%20Sarasota%2C%20FL%2034243%2C%20EE.%20UU.!5e0!3m2!1ses!2sco!4v1692907479088!5m2!1ses!2sco"
                    width="100%"
                    height="450"
                    style={{ border:"0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps"                ></iframe>
            </div>
            <Footer />
        </section>
    )
}

export default ContactUs;
